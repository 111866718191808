<!--
Modal Wrapper for EditHeatGrid
-->
<i18n>
{
  "de": {
    "editTitle": "Wärmenetz bearbeiten",
    "createTitle": "Wärmenetz erstellen",
    "buildingsTitle": "Aktuell angeschlossene Liegenschaften",
    "identifierTitle": "Nr.",
    "total": "Total",
    "descriptionTitle": "Bezeichnung",
    "endEnergyTitle": "Endenergiebedarf"
  }
}
</i18n>

<template>
  <Modal wide class="edit-heat-grid-modal" @close="$emit('close', $event)">
    <template #header>
      <h1 v-if="!heatGrid">{{ $t('createTitle') }}</h1>
      <h1 v-else>{{ $t('editTitle') }}</h1>
    </template>
    <EditHeatGrid :portfolio="portfolio" :heat-grid="heatGrid" @save="onSave" @cancel="onCancel" />
    <br /><br />
    
    <div v-if="heatGrid !== null">
      <h2>{{ $t('buildingsTitle') }}</h2>
      <div v-if="buildings === null" class="spinner">
        <ClipLoader size="18px" color="#000" />
      </div>
      <template v-else>
        <DetailList v-if="buildings" has-header has-footer class="buildings-table">
          <template #header>
            <span>{{ $t('identifierTitle') }}</span>
            <span>{{ $t('descriptionTitle') }}</span>
            <span class="align-right">{{ $t('endEnergyTitle') }}</span>
          </template>
          <li v-for="(building, index) in buildings" :key="index">
            <span>{{ building.identifier }}</span>
            <span>{{ building.description }}</span>
            <span class="align-right">{{ formatNumber(getEndEnergy(building) / 1000, 1) }} MWh</span>
          </li>
          <template #footer>
            <span>{{ $t('total') }}</span>
            <span></span>
            <span class="align-right">{{ formatNumber(totalEndEnergy / 1000, 1) }} MWh</span>
          </template>
        </DetailList>
      </template>
    </div>
  </Modal>
</template>

<script>
import compassApi from '@/services/compass-api.js'

import Modal from '@/components/shared/Modal.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import EditHeatGrid from '@/components/settings/heat_grids/EditHeatGrid.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    Modal,
    EditHeatGrid,
    DetailList,
    ClipLoader,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    heatGrid: {
      // edit existing heat grid
      type: Object,
    },
  },

  data() {
    return {
      buildings: null,
    }
  },

  computed: {
    totalEndEnergy() {
      return this.buildings && this.buildings.reduce((pv, cv) => pv + this.getEndEnergy(cv), 0)
    },
  },

  watch: {
    heatGrid() {
      this.getBuildings()
    },
  },

  created() {
    this.getBuildings()
  },

  methods: {
    async getBuildings() {
      if (this.heatGrid) {
        this.buildings = await compassApi.call(`/heat_grids/${this.heatGrid.id}/buildings/today`)
      } else {
        this.buildings = null
      }
    },

    getEndEnergy(building) {
      return building.heating
        .filter((h) => h.grid_id === this.heatGrid.id)
        .reduce((pv, cv) => pv + cv.end_energy * cv.calibration_factor, 0)
    },

    onCancel() {
      if (this.heatGrid === null) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },

    onSave() {
      if (this.heatGrid === null) {
        // Close dialog if adding new is saved
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped>
.spinner {
  display: flex;
  justify-content: left;
}
</style>

<style>
.edit-heat-grid-modal .detail-list.buildings-table > ul > li {
  grid-template-columns: 1fr 4fr 1fr;
}
.edit-heat-grid-modal .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
