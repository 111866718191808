<i18n>
{
  "de": {
    "pageTitle": "Wärmenetze",
    "settings": "Einstellungen",
    "addButton": "Wärmenetz hinzufügen",
    "idTitle": "Identifikator",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "returnButton": "Zurück",
    "confirmDelete": "Wollen Sie dieses Wärmenetz wirklich löschen? Falls noch Liegenschaften damit verknüpft sind werden diese auf Standard-Fernwärmemix zurückgesetzt.",
    "noGrids": "In diesem Portfolio sind noch keine Wärmenetze vorhanden."
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="heat-grids-page">
    <template #default>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>

      <DetailList v-if="portfolio.heat_grids.length" has-header class="grids-table">
        <template #header>
          <span>{{ $t('idTitle') }}</span>
          <span>{{ $t('nameTitle') }}</span>
          <span></span>
        </template>
        <li v-for="(heat_grid, index) in heatGrids" :key="index" class="heat-grid-row">
          <span>{{ heat_grid.identifier }}</span>
          <span>{{ heat_grid.name }}</span>
          <ListButtonWrapper>
            <ListEditButton
              v-if="getPortfolioPermission('EDIT_HEAT_GRIDS')"
              @click="
                editGrid = heat_grid
                editModalOpen = true
              "
            />
            <ListDeleteButton v-if="getPortfolioPermission('EDIT_HEAT_GRIDS')" @click="onDelete(heat_grid)" />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <div v-else>
        {{ $t('noGrids') }}
      </div>
      <ButtonWrapper>
        <a
          v-if="getPortfolioPermission('EDIT_HEAT_GRIDS')"
          href="#"
          class="button"
          @click.prevent="
            editGrid = null
            editModalOpen = true
          "
          >{{ $t('addButton') }}</a
        >
      </ButtonWrapper>

      <EditHeatGridModal
        v-if="editModalOpen"
        :portfolio="portfolio"
        :heat-grid="editGrid"
        @close="editModalOpen = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import EditHeatGridModal from '@/components/settings/heat_grids/EditHeatGridModal.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'settingsHeatGrids',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ButtonWrapper,
    MainLayout,
    DetailList,
    EditHeatGridModal,
    PageTitle,
  },

  data() {
    return {
      editGrid: null,
      editModalOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    heatGrids() {
      return this.portfolio.heat_grids.map((hg) => {
        return {
          ...hg,
          heaters: hg.heaters.map((h) => {
            return {
              ...h,
              heating_type_id: this.getHeatingTypeName(h.heating_type_id),
            }
          }),
        }
      })
    },
  },

  methods: {
    ...mapActions({
      deleteHeatGrid: 'portfolio/deleteHeatGrid',
    }),

    async onDelete(grid) {
      if (confirm(this.$t('confirmDelete'))) {
        try {
          this.error = null
          await this.deleteHeatGrid(grid)
          this.$emit('close')
        } catch (error) {
          this.error = error
        }
      }
    },

    getHeatingTypeName(id) {
      const heatingType = this.portfolio.heating_types.find((ht) => id === ht.id)
      if (heatingType) {
        return `${heatingType.name}`
      } else {
        return 'UNKNOWN'
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.heat-grids-page .grids-table.detail-list > ul > li {
  grid-template-columns: 200px 1fr 160px 120px;
}
</style>
